








































































































































import Vue from "vue";
import Layout from "@/router/layouts/main.vue";
import { ToggleButton } from "vue-js-toggle-button";
import {mapActions, mapGetters} from "vuex";

export default Vue.extend({
  name: "role-management-admin" as string,

  components: {
    Layout,
    ToggleButton
  },

  // async created(): Promise<void> {
  //   await this.checkLoggedUserRolePermission({ permissionId: 12 });
  // },

  async mounted(): Promise<void> {
    // await this.setCompany(this.companyId);
    await this.setRolePermissions();
    await this.setRolePermissionsAssociations();
  },

  computed: {
    ...mapGetters("companyModule", {
      roleManagementState: "GET_ROLE_MANAGEMENT_STATE",
      companyId: "GET_COMPANY_ID"
    }),
    ...mapGetters("userModule", {
      hasRoleManagementPermissions: "GET_ROLE_MANAGEMENT_PERMISSION"
    })
  },

  methods: {
    ...mapActions("companyModule", {
      setRolePermissions: "FETCH_ROLE_PERMISSIONS",
      setRolePermissionsAssociations: "FETCH_ROLE_PERMISSIONS_ASSOCIATIONS",
      setCompany: "SET_COMPANY"
    }),
    ...mapActions("userModule", {
      addUserPermission: "ADD_USER_PERMISSION",
      removeUserPermission: "REMOVE_USER_PERMISSION"
    }),
    ...mapActions("userModule", {
      checkLoggedUserRolePermission: "CHECK_LOGGED_USER_ROLE_PERMISSION"
    }),
    async togglePermission(value: boolean, roleId: number, permissionId: number, permissionAssociationId: number): Promise<void> {
      if (value) {
        const payload: Array<object> = [{
          role_id: roleId,
          permission_id: permissionId,
          venue_owner_id: this.companyId
        }]

        await this.addUserPermission(payload);
      } else {
        const payload: object = {
          id: permissionAssociationId,
          permissionId: permissionId
        }

        await this.removeUserPermission(payload);
      }
    }
  },

  watch: {
    companyId: {
      async handler(value: number): Promise<void> {
        if (value) {
          await this.setRolePermissionsAssociations();
        }
      }
    }
  }
})
